import { Button } from "carbon-components-react"
import { Filter16 } from "components/atoms/IconsCreateFactory"
import FormBuilder from "modules/formBuilder/FormBuilder"
import { useTrans } from "system/translations/hooks"
import { AdaptiveFilterFormProps } from "./types"

const AdaptiveFilterForm: React.FC<AdaptiveFilterFormProps> = ({ isMobile, showFilters, searchFormConfig, onFilterSubmit }) => {
  const {_t} = useTrans()
  return isMobile
      ? <>
        <Button
          kind="ghost"
          style={{ color: "#252C32" }}
          onClick={showFilters}
          renderIcon={Filter16}
        >
          {_t("filters")}
        </Button>
      </>
      : <FormBuilder
          formItemsConfig={searchFormConfig}
          showSubmit={false}
          formProps={{
            onSubmit: onFilterSubmit,
            initialValues: {},
          }}
        />
}

export default AdaptiveFilterForm