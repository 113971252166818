import DotsMenu from "components/atoms/DotsMenu";
import InfoTag from "components/atoms/InfoTag";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { FormConfigType } from "modules/formBuilder/types";
import { TableColumnType } from "modules/table/types";
import { useCallback, useMemo } from "react";
import { ArrayOptionsMapRes } from "system/helpers/types";
import { useTrans } from "system/translations/hooks";
import { filterOptions } from "./constants";
import { FormConfigUpdatePIStatusType, PaymentInstrumentItemState } from "./types";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();

  const activeStatusColumn = [{
    width: 140,
    Header: _t("status"),
    mobileVisible: true,
    Cell: (props: any) => {
      const getInfoTagProps = (): {status: string, label: string} => {
        const activationState: PaymentInstrumentItemState = props?.row?.original?.state

        switch(activationState) {
          case "ACTIVE": 
            return {
              status: "active",
              label: _t("active"),
            }
          case "INACTIVE": 
            return {
              status: "deactivated",
              label: _t("inactive"),
            }
          default: 
            return {
              status: "error",
              label: _t("deactivated_on_PSP_side"),
            }
            
        }
      }
      return ( <InfoTag type={"secondary"} {...getInfoTagProps()} /> );
    },
  }]

  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t('transactionUUID'),
        accessor: "previousTransactionId",
        id: "previousTransactionId",
        mobileVisible: true,
        isMobileTitle: true,
        width: 250,
      },
      {
        Header: <div className="mb--cell">{ _t("payment_instrument_type")}</div>,
        accessor: "instrumentType",
        id: "instrumentType",
        mobileVisible: true,
      },
      {
        Header: <div className="mb--cell">{ _t("customer_id")}</div>,
        accessor: "customerId",
        id: "customerId",
        width: 100,
        mobileVisible: true,
      },
      {
        Header: <div className="mb--cell">{ _t("identification_key")}</div>,
        accessor: "identificationKey",
        id: "identificationKey",
        mobileVisible: true,
        width: 170
      },
      {
        Header: <div className="mb--cell">{ _t("billing_name")}</div>,
        accessor: "name",
        id: "name",
        mobileVisible: true,
        Cell: ({row}: any) => {
          return <span>{row?.original?.firstName + " " + row?.original?.lastName}</span> 
        }
      },
      {
        Header: <div className="mb--cell">{ _t("customer_email")}</div>,
        accessor: "customerEmail",
        id: "customerEmail",
        mobileVisible: true,
      },
      {
        Header: <div className="mb--cell">{ _t("payment_gateway_code")}</div>,
        accessor: "paymentGatewayCode",
        id: "paymentGatewayCode",
        width: 180,
        mobileVisible: true,
      },
      {
        Header: <div className="mb--cell">{ _t("payment_gateway_name")}</div>,
        accessor: "paymentGatewayName",
        id: "paymentGatewayName",
        mobileVisible: true,
      },
      ...activeStatusColumn,
      {
        Header:  <div className="mb--cell">{ _t("created_date")}</div>,
        accessor: "createdDate",
        id: "createdDate",
        mobileVisible: true,
      },
      {
        Header:  <div className="mb--cell">{ _t("additional_info")}</div>,
        accessor: "additionalData",
        id: "additionalData",
        mobileVisible: true,
      },
      {
        Header: _t("comment"),
        accessor: "comment",
        id: "comment",
        mobileVisible: true,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          const rowData = props?.row?.original
          const isActive = rowData?.state === "ACTIVE"
          const isSuspended = rowData?.state === "SUSPENDED";
          const isRelevantGateway = ["SIBS_MULTIBANCO", "SIBS_MBWAY"].includes(rowData?.paymentGatewayCode);
          const canDeactivateOnPSPSide = isRelevantGateway && !isSuspended;

          return (
            <RoleWrapper accessTo={"ROLE_MODIFY_EXTERNAL_CUSTOMER_IDENTIFIERS"}>
              <DotsMenu
                menuClassName={"dots-menu__max-width"}
                clickParams={{
                  id: props?.row?.original?.id,
                  allData: props?.row?.original,
                }}
                items={props?.getActionMenuItems({ isActive, canDeactivateOnPSPSide })}
              />
            </RoleWrapper>
          );
        },
      },
    ],
    [activeStatusColumn]
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const filterByOptions: ArrayOptionsMapRes = useMemo(
    () => filterOptions(_t),
    [_t]
  );
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        name: "value"
      }),
      fields.filters.filterField({
        name: "filter",
        componentProps: {
          items: filterByOptions,
        },
      }),
      // fields.filters.date({
      //   componentProps: {
      //     openLeft: true
      //   }
      // }),
      fields.general.submitBtn(),
    ];
  }, []);
  return formConfig;
};

export const useUpdatePaymentInstrumentStatus = (): FormConfigUpdatePIStatusType => {
  const fields = useDefaultFieldsConfig();
  const getformConfig: FormConfigUpdatePIStatusType = useCallback((status, { title, icon, type = "secondary"}): FormConfigType => {
    return [
      fields.formFields.comment(),
      fields.general.saveBtn({
        emptySpace: {},
        componentProps: {
          kind: type ,
          label: title,
          renderIcon: icon
        },
        columnParams: {
          className: 'submit-button-modal',
        }
      })
    ];
  }, []);
  
  return getformConfig;
}