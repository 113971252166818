import {
  useGetMerchantSlackChannels,
  useGetMerchantsNameList
} from "modules/merchants/apiHooks";
import { useGetPaymentGateways } from "modules/merchantsBalanceV2/apiHooks";
import { useMemo } from "react";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import {
  useGetAllPaymentGateways,
  useGetMerchantPendingTransactionMonitoringGateways,
  useGetPaymentGatewaysByMerchant
} from "./apiHooks";
import {
  UseFormHelpersProps,
  UseMonitoringModuleDataRes,
  UseSearchMonitoringModuleDataRes
} from "./types";


export const useMonitoringModuleData = (merchantId?: number): UseMonitoringModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentGateways = [] } = useGetMerchantPendingTransactionMonitoringGateways();
  const { data: paymentGatewaysByMerchant = [] } = useGetPaymentGatewaysByMerchant(merchantId || 0);
  const { data: merchantSlackChannels = [] } = useGetMerchantSlackChannels(merchantId || 0);

  const merchantsOptions = merchantsList.map((item) => ({
    label: item.name,
    value: item,
  }));

  const merchantSlackChannelOptions = merchantSlackChannels.map((item) => ({
    label: item.channelName,
    value: {
      id: item.id,
      name: item.channelName
    },
  }));

  const paymentGatewaysOptionsAll = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });

  const paymentGatewaysOptionsByMerchant = arrayOptionsMap(paymentGatewaysByMerchant, {
    labelKey: "value",
    valueKey: "label",
  });

  return {
    merchantsOptions,
    paymentGatewaysOptionsAll,
    paymentGatewaysOptionsByMerchant,
    merchantSlackChannelOptions
  };
};

export const useSearchMonitoringModuleData = (): UseSearchMonitoringModuleDataRes => {
  const { data: merchantsList = [] } = useGetMerchantsNameList();
  const { data: paymentGateways = [] } = useGetPaymentGateways();
  
  const merchantsOptions = arrayOptionsMap(merchantsList, {
    labelKey: "name",
    valueKey: "id",
  });

  const paymentGatewaysOptions = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });

  return {
    merchantsOptions,
    paymentGatewaysOptions,
  };
};

export const useEditMonitoringModuleData = (merchantId?: number): UseMonitoringModuleDataRes => {
  const { data: merchantSlackChannels = [] } = useGetMerchantSlackChannels(merchantId || 0);
  const { data: paymentGateways = [] } = useGetAllPaymentGateways();

    const merchantSlackChannelOptions = merchantSlackChannels.map((item) => ({
    label: item.channelName,
    value: {
      id: item.id,
      name: item.channelName
    },
  }));

  const paymentGatewaysOptionsAll = arrayOptionsMap(paymentGateways, {
    labelKey: "label",
    valueKey: "value",
  });

  return {
    merchantSlackChannelOptions,
    paymentGatewaysOptionsAll
  };
};

export const useFormHelpers = ({ isEdit, data }: UseFormHelpersProps) => {
  const initialValues = useMemo(() => {
    if (isEdit && data) {
      return {
        ...data,
        channel: { id: data.channelId, name: data.channelName },
        merchant: { id: data.merchantId, name: data.merchantName  },
      };
    }

    return {};
  }, [isEdit, data]);

  const normalizeData = (formData: any) => {
    const { channel, merchant, getAllGateways, ...rest } = formData;

    return {
      ...rest,
      channelId: channel?.id,
      channelName: channel?.name,
      merchantId: merchant?.id,
      merchantName: merchant?.name,
    };
  };

    return { initialValues, normalizeData }
}
