import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING,
  API_CREATE_MERCHANT_PENDING_TRANSACTIONS_MONITORING,
  API_DELETE_MERCHANT_PENDING_TRANSACTIONS_MONITORING,
  API_UPDATE_MERCHANT_PENDING_TRANSACTIONS_MONITORING,
  API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_ITEM,
  API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_PAYMENT_GATEWAY_CODES,
  API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_PAYMENT_GATEWAY_CODES_BY_MERCHANT,
  API_GET_MERCHANTS_BALANCE_V2_All_GATEWAYS,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { generatePath } from "react-router-dom";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import {
  MerchantPendingTransactionsMonitoringDataType,
  MerchantPendingTransactionsMonitoringItemType,
  GatewaysDataType
} from "modules/merchantPendingTransactionsMonitoring/types";

const getPaymentGatewayCodes = "getPaymentGatewayCodes";
const getAllPaymentGatewayCodes = "getAllPaymentGatewayCodesKey";
const getPaymentGatewaysByMerchantKey = "getPaymentGatewaysByMerchantKey";
const getMerchantPendingTransactionsMonitoringKey = "getMerchantPendingTransactionsMonitoringKey";
const getMerchantPendingTransactionsMonitoringItemKey = "getMerchantPendingTransactionsMonitoringItemKey";
export const useGetMerchantPendingTransactionsMonitoring = (filters: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantPendingTransactionsMonitoringKey, filters],
    (): Promise<MerchantPendingTransactionsMonitoringDataType> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING, filters),
        method: "get",
      })
  );
};
export const useGetMerchantPendingTransactionsMonitoringItem = (id: any = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getMerchantPendingTransactionsMonitoringItemKey, id],
    (): Promise<MerchantPendingTransactionsMonitoringItemType> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_ITEM, { id }),
        method: "get",
      })
  );
};

export const useCreateMerchantPendingTransactionsMonitoring = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_MERCHANT_PENDING_TRANSACTIONS_MONITORING,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantPendingTransactionsMonitoringKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useDeleteMerchantPendingTransactionsMonitoring = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_MERCHANT_PENDING_TRANSACTIONS_MONITORING, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantPendingTransactionsMonitoringKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateMerchantPendingTransactionsMonitoring = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_UPDATE_MERCHANT_PENDING_TRANSACTIONS_MONITORING,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantPendingTransactionsMonitoringKey);
        queryClient.invalidateQueries(getMerchantPendingTransactionsMonitoringItemKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetMerchantPendingTransactionMonitoringGateways = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentGatewayCodes],
    (): Promise<any> =>
      apiRequest({
        url: API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_PAYMENT_GATEWAY_CODES,
        method: "get",
      })
  );
};

export const useGetAllPaymentGateways = (isEnabeld?: boolean) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getAllPaymentGatewayCodes],
    (): Promise<GatewaysDataType> =>
      apiRequest({
        url: API_GET_MERCHANTS_BALANCE_V2_All_GATEWAYS,
        method: "get",
      }),
      { enabled: !isEnabeld }
  );
};

export const useGetPaymentGatewaysByMerchant = (merchantId?: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentGatewaysByMerchantKey, merchantId],
    (): Promise<GatewaysDataType> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_PAYMENT_GATEWAY_CODES_BY_MERCHANT, { merchantId:  `${merchantId}`}),
        method: "get",
      }),
    { enabled: !!merchantId }
  );
};
