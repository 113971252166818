export const LOGIN_URL = "/api/authenticate";
export const LOGOUT_URL = "/api/authenticate/logout";
export const LOGIN_TFA_URL = "/api/authenticate/tfa";
export const FORGOT_PASSWORD_URL = "/api/reset-password";
export const RESET_PASSWORD_URL = "/api/reset-password";
export const API_CHANGE_PASSWORD = "/api/global/password";

export const API_GET_CURRENT_ADMIN = "/api/administration/users/currentUser";
export const API_GET_USERS =
  "/api/administration/users?sortDir=DESC&sortField=id";
export const API_GET_USER = "/api/administration/users/:id";
export const API_CREATE_USER = "/api/administration/users";
export const API_UPDATE_USER = "/api/administration/users/:id";
export const API_ENABLE_USER_TFA = "/api/global/2fa";
export const API_USER_RESET_TFA = "/api/administration/users/:id/resetTfa";
export const API_DELETE_USER = "/api/administration/users/:id";
export const API_RESET_USER_PASSWORD = "api/reset-password/force";
export const API_RESEND_EMAIL_CONFIRMATION_LINK =
  "/api/administration/users/resend-link/:id";

export const API_GET_ROLES = "/api/roles/security-groups";
export const API_GET_AUTHORITIES = "/api/roles/authorities";
export const API_GET_ROLE = "/api/roles/security-groups/:id";
export const API_CREATE_ROLE = "/api/roles/security-groups";
export const API_UPDATE_ROLE = "/api/roles/security-groups/:id";
export const API_DELETE_ROLE = "/api/roles/security-groups/:id";

export const API_GET_MERCHANTS = "/api/merchants";
export const API_GET_SUB_MERCHANTS =
  "/api/merchants/:merchantId/sub?page=1&perPage=999";
export const API_GET_MERCHANT = "/api/merchants/:id";
export const API_GET_MERCHANTS_NAME_LIST = "/api/merchants/nameList";
export const API_CREATE_MERCHANT = "/api/merchants";
export const API_UPDATE_MERCHANT = "/api/merchants/:id";
export const API_DELETE_MERCHANT = "/api/merchants/:id";
export const API_MERCHANT_PAYMENT_METHODS = "/api/merchants/:id/paymentMethods";
export const API_MERCHANT_GET_PAYMENT_METHOD_CREDENTIALS =
  "/api/settings/paymentGWs/:methodId/credentials";
export const API_MERCHANT_GET_PAYMENT_METHOD_PROVIDERS =
  "/api/settings/paymentGWs/providers/:paymentId";
export const API_MERCHANT_GET_PAYMENT_GATEWAY_CODES =
  "/api/settings/paymentGWs";
export const API_MERCHANT_PAYMENT_WITHDRAWAL_METHODS =
  "/api/merchants/:id/withdrawalMethods";
export const API_MERCHANT_PAYMENT_METHOD_BY_ID =
  "/api/merchants/:merchantId/paymentMethodsSpecification/:id";
export const API_MERCHANT_PAYMENT_WITHDRAWAL_METHOD_BY_ID =
  "/api/merchants/:merchantId/withdrawalMethodsSpecification/:id";
export const API_MERCHANT_AVAILABLE_DEPOSIT_METHODS =
  "/api/merchants/:id/availableMethods";
export const API_MERCHANT_AVAILABLE_WITHDRAWAL_METHODS =
  "/api/merchants/:id/availableWithdrawalMethods";
export const API_MERCHANT_CREATE_PAYMENT_DEPOSIT_METHOD =
  "/api/merchants/:merchantId/paymentMethods";
export const API_MERCHANT_CREATE_PAYMENT_WITHDRAWAL_METHOD =
  "/api/merchants/:merchantId/withdrawalMethods";
export const API_MERCHANT_EDIT_PAYMENT_DEPOSIT_METHOD =
  "/api/merchants/:merchantId/paymentMethods/:id";
export const API_MERCHANT_EDIT_PAYMENT_WITHDRAWAL_METHOD =
  "/api/merchants/:merchantId/withdrawalMethods/:id";
export const API_MERCHANT_DELETE_PAYMENT_DEPOSIT_METHOD =
  "/api/merchants/:merchantId/paymentMethods/:id";
export const API_MERCHANT_DELETE_PAYMENT_WITHDRAWAL_METHOD =
  "/api/merchants/:merchantId/withdrawalMethods/:id";
export const API_GET_MERCHANT_BLACK_LIST =
  "/api/merchants/:merchantId/blacklist";
export const API_GET_MERCHANT_BLACK_LIST_BY_ID =
  "/api/merchants/:merchantId/blacklist/:id";
export const API_CREATE_MERCHANT_BLACK_LIST =
  "/api/merchants/:merchantId/blacklist/:email";
export const API_UPDATE_MERCHANT_BLACK_LIST =
  "/api/merchants/:merchantId/blacklist/:id";
export const API_DELETE_MERCHANT_BLACK_LIST =
  "/api/merchants/:merchantId/blacklist/:id";

export const API_GET_MERCHANT_WHITE_LIST =
  "/api/merchants/:merchantId/restricts?page=1&perPage=100";
export const API_CREATE_MERCHANT_WHITE_LIST =
  "/api/merchants/:merchantId/restricts";
export const API_GET_MERCHANT_WHITE_LIST_BY_ID =
  "/api/merchants/:merchantId/restricts/:id";
export const API_UPDATE_MERCHANT_WHITE_LIST =
  "/api/merchants/:merchantId/restricts/:id";
export const API_DELETE_MERCHANT_WHITE_LIST =
  "/api/merchants/:merchantId/restricts/:id";

export const API_GET_TRANSLATIONS = "/api/translations";
export const API_GET_TRANSLATIONS_ITEM = "/api/translations/:id";
export const API_CREATE_TRANSLATIONS = "/api/translations/:id";
export const API_UPDATE_TRANSLATIONS = "/api/translations/:id";
export const API_DELETE_TRANSLATIONS = "/api/translations/:id";

export const API_GET_TRANSACTIONS = "/api/transactions";
export const API_UPDATE_TRANSACTION =
  "/api/transactions/change-transaction-status";
export const API_GET_TRANSACTIONS_EXPORT = "/api/transactions/export";
export const API_GET_TRANSACTION = "/api/transactions/:id";
export const API_GET_PAYMENT_METHODS = "api/transactions/pmList";
export const API_GET_PAYMENT_METHODS_WITH_ID = "api/merchants/paymentMethods";
export const API_GET_PAYMENT_GATEWAYS = "api/transactions/gwList";
export const API_UPDATE_RESEND_IPN = "/api/notifications/resentIpn/:id";
export const API_CHANGE_CUSTOMER_STATUS = "/api/clients/change-customer-status";
export const API_GET_KIBANA_HISTORY_BY_TRANSACTION = "/api/transactions/history/kibana/:transactionUUID";

export const API_GET_CLIENTS = "/api/clients";
export const API_GET_CLIENT = "/api/clients/:id";

export const API_GET_CREDENTIALS = "/api/settings/credentials";
export const API_GET_CREDENTIAL =
  "/api/settings/credentials/type/:credentialId";
export const API_GET_CREDENTIAL_TEMPLATE =
  "/api/settings/credentials/type/:credentialId";
export const API_CREATE_CREDENTIAL = "/api/settings/credentials/:credentialId";
export const API_UPDATE_CREDENTIAL = "/api/settings/credentials/:credentialId";
export const API_DELETE_CREDENTIAL = "/api/settings/credentials/:id";

export const API_GET_SETTINGS = "/api/settings";
export const API_GET_SETTING = "/api/settings/:settingId";
export const API_GET_SETTING_BY_ID = "/api/settings/:settingId/:id";
export const API_GET_SETTING_TEMPLATE = "/api/settings/:settingId/template";
export const API_CREATE_SETTING = "/api/settings/:settingId";
export const API_UPDATE_SETTING = "/api/settings/:settingId";
export const API_UPDATE_SETTING_CREDENTIALS = "/api/settings/:settingId/:type";
export const API_CREATE_SETTING_CREDENTIALS = "/api/settings/:settingId/:type";
export const API_DELETE_SETTING = "/api/settings/:settingId/:id";
export const API_UPDATE_SETTING_CRON_JOB = "/api/settings/cronJobs/:id/execute";
export const API_GET_SETTING_PAYMENT_METHODS = "/api/settings/paymentMethods";
export const API_DUPLICATE_SETTING_PAYMENT_GW = "/api/settings/paymentGWs";
export const API_GET_SETTING_CURRENCIES = "/api/settings/currencies";
export const API_GET_SETTING_COUNTRIES = "/api/settings/countries";
export const API_GET_SETTING_OPERATIONS_TYPES = "/api/settings/transactionOperations";

export const API_GET_GATEWAY_WHITE_LIST = "/api/gateway/:pgwId/restricts";
export const API_CREATE_GATEWAY_WHITE_LIST = "/api/gateway/:pgwId/restricts";
export const API_GET_GATEWAY_WHITE_LIST_BY_ID = "/api/gateway/restricts/:id";
export const API_UPDATE_GATEWAY_WHITE_LIST =
  "/api/gateway/:pgwId/restricts/:id";
export const API_DELETE_GATEWAY_WHITE_LIST =
  "/api/gateway/:pgwId/restricts/:id";

export const API_GET_MERCHANT_BALANCE_CURRENCIES =
  "/api/merchant-balance/currencies";
export const API_GET_MERCHANTS_BALANCE = "/api/merchant-balance";
export const API_GET_MERCHANTS_BALANCE_EXPORT = "/api/merchant-balance/export";

export const API_GET_MERCHANTS_BALANCE_V2 = "/api/merchant-balance-v2/main";
export const API_GET_MERCHANTS_BALANCE_V2_CURRENCY = "/api/merchant-balance-v2/:merchantId/:currency";
export const API_GET_MERCHANTS_BALANCE_V2_AMOUNT = "/api/merchant-balance-v2/transactions/:merchantId/:currency";
export const API_CREATE_MERCHANTS_BALANCE_V2_MANUAL_TRANSACTION = "/api/merchant-balance-v2/manualTransaction/:merchantId/:currency";
export const API_UPDATE_MERCHANTS_BALANCE_V2_MANUAL_TRANSACTION_ITEM = "/api/merchant-balance-v2/manualTransaction/:id";
export const API_GET_MERCHANTS_BALANCE_V2_NOT_CONFIGURET_GATEWAYS = "/api/merchant-balance-v2/:merchantId/gateways/excluded";
export const API_GET_MERCHANTS_BALANCE_V2_All_GATEWAYS = "/api/merchant-balance-v2/gateways/all";
export const API_CLOSE_PERIOD_MERCHANTS_BALANCE_V2 = "/api/merchant-balance-v2/:id";
export const API_GET_ACTIVE_GATEWAYS_MERCHANTS_BALANCE_V2_MANUAL_TRANSACTION = "/api/merchant-balance-v2/:merchantId/gateways/active";
export const API_GET_LAST_CLOSED_PERIOD_MERCHANTS_BALANCE_V2 = "/api/merchant-balance-v2/closed/:merchantId/:currency";
export const API_GET_MERCHANTS_BALANCE_V2_DETAILS_EXPORT = "/api/merchant-balance-v2/:merchantId/:currency/exportReport";
export const API_GET_MERCHANTS_BALANCE_V2_DETAILS_TRANSACTION_EXPORT = "/api/merchant-balance-v2/:merchantId/:currency/exportTransactions";
export const API_GET_MERCHANTS_BALANCE_V2_DETAILS_ATTACHMENT_ITEM = "/api/merchant-balance-v2/manualTransaction/attachment/:id";

export const API_GET_MERCHANTS_FEE = "/api/mb-fee/active";
export const API_CREATE_MERCHANTS_FEE = "/api/mb-fee";
export const API_UPDATE_MERCHANTS_FEE = "/api/mb-fee/:id";
export const API_UPDATE_ADVANCED_MERCHANTS_FEE = "/api/mb-fee";
export const API_GET_MERCHANTS_FEE_ITEM = "/api/mb-fee/:id";
export const API_GET_MERCHANTS_FEE_HISTORY_BY_FIELD = "/api/mb-history/:merchantId/:paymentGateway/FEE_SETTING_UPDATE/:fieldName"
export const API_GET_MERCHANTS_FEE_HISTORY = "/api/mb-fee/all/:merchantId/:paymentGateway/:fieldName"
export const API_GET_MERCHANTS_BY_GATEWAY = "/api/merchant-balance-v2/gateways"

export const API_GET_MERCHANTS_CONFIGURATION = "/api/mb-settings/active"
export const API_GET_MERCHANTS_CONFIGURATION_ITEM = "/api/mb-settings/:id"
export const API_UPDATE_MERCHANTS_CONFIGURATION_ITEM = "/api/mb-settings/"
export const API_CREATE_MERCHANTS_CONFIGURATION_ITEM = "/api/mb-settings"


export const API_GET_PAYMENT_GATEWAYS_BY_MERCHANT = "/api/mb-settings/:merchantId/gateways"

export const API_GET_MERCHANTS_CONFIGURATION_MANUAL_RATES = "/api/mb-rates/:merchantId/:paymentGateway"
export const API_CREATE_MERCHANTS_CONFIGURATION_MANUAL_RATE = "/api/mb-rates"
export const API_UPDATE_MERCHANTS_CONFIGURATION_MANUAL_RATE = "/api/mb-rates"
export const API_DELETE_MERCHANTS_CONFIGURATION_MANUAL_RATE_ITEM = "/api/mb-rates/:id"

export const API_GET_MERCHANTS_BALANCE_V2_HISTORY = '/api/mb-history'

export const API_GET_REQUEST_HISTORY =
  "/api/transactions/history?page=-1&size=100";
// "/api/transactions/history?field=transactionUUID&page=-1&request=54205-1644589731-3&size=100"
export const API_GET_ACTION_LOG = "/api/action-log";
///api/action-log?entityType=ACTION_LOG&userEmail=superadmin@localhost&page=1&sortDir=DESC&sortField=date

// export const API_GET_MONITORING = "/api/monitoring-settings";
export const API_GET_MONITORING_DEPOSIT = "/api/monitoring-settings/deposit";
export const API_GET_MONITORING_WITHDRAWAL = "/api/monitoring-settings/withdrawal";
export const API_GET_MONITORING_ITEM = "/api/monitoring-settings/:id";
export const API_GET_MONITORING_TEMPLATE = "/api/monitoring-settings/template";
export const API_CREATE_MONITORING = "/api/monitoring-settings";
export const API_UPDATE_MONITORING = "/api/monitoring-settings";
export const API_DELETE_MONITORING = "/api/monitoring-settings/:id";

export const API_GET_MONITORING_MERCHANT =
  "/api/merchants/:merchantId/alerts-settings";
export const API_GET_MONITORING_MERCHANT_ALL =
  "/api/merchants/alerts-settings";
export const API_GET_MONITORING_MERCHANT_ITEM =
  "/api/merchants/alerts-settings/:id";
export const API_GET_MONITORING_MERCHANT_TEMPLATE =
  "/api/merchants/alerts-settings/template";
export const API_CREATE_MONITORING_MERCHANT = "/api/merchants/alerts-settings";
export const API_UPDATE_MONITORING_MERCHANT = "/api/merchants/alerts-settings";
export const API_DELETE_MONITORING_MERCHANT =
  "/api/merchants/alerts-settings/:id";

export const API_GET_ALERTS = "/api/merchants/alert-recipient-settings";
export const API_GET_ALERT = "/api/merchants/alert-recipient-settings/:id";
export const API_GET_ALERT_TEMPLATE =
  "/api/merchants/alert-recipient-settings/template";
export const API_CREATE_ALERT = "/api/merchants/alert-recipient-settings";
export const API_UPDATE_ALERT = "api/merchants/alert-recipient-settings";
export const API_DELETE_ALERT = "/api/merchants/alert-recipient-settings/:id";


export const API_GET_PENDING_TRANSACTIONS_MONITORING = "/api/monitoring/not-completed-transactions-alert";
export const API_GET_PENDING_TRANSACTIONS_MONITORING_ITEM = "/api/monitoring/not-completed-transactions-alert/:id";
export const API_GET_PENDING_TRANSACTIONS_MONITORING_TEMPLATE = "/api/monitoring/not-completed-transactions-alert/template";

export const API_GET_PENDING_TRANSACTIONS_MONITORING_PAYMENT_GATEWAY_CODES =
  "/api/monitoring/not-completed-transactions-alert/gateways";

export const API_CREATE_PENDING_TRANSACTIONS_MONITORING = "/api/monitoring/not-completed-transactions-alert";
export const API_UPDATE_PENDING_TRANSACTIONS_MONITORING = "/api/monitoring/not-completed-transactions-alert";
export const API_DELETE_PENDING_TRANSACTIONS_MONITORING = "/api/monitoring/not-completed-transactions-alert/:id";

export const API_GET_ANALYTICS = "/api/analytics";

export const API_GET_PAYMENT_ORCHESTRATION = "/api/settings/rules/configuration";
export const API_GET_PAYMENT_ORCHESTRATION_ITEM = "/api/settings/rules/configuration/:id";
export const API_DELETE_PAYMENT_ORCHESTRATION_ITEM = "/api/settings/rules/configuration/:id";
export const API_COPY_PAYMENT_ORCHESTRATION_ITEM = "/api/settings/rules/configuration/:id/clone";
export const API_CREATE_PAYMENT_ORCHESTRATION_ITEM = "/api/settings/rules/configuration";
export const API_GET_PAYMENT_ORCHESTRATION_HISTORY= "/api/orchestration/history";
export const API_GET_BINS_PARAMS = "/api/bins/params";
export const API_GET_BINS = "/api/bins/params";// :numberBin to get info for one of the bins
export const API_GET_TEMPLATES_TYPES = "/api/settings/rules/configuration/template/types";
export const API_GET_ORCHESTRATED_GATEWAYS_BY_MERCHANT = "/api/settings/rules/configuration/merchant/:merchantId/gateways";

export const GET_SWAGGER_DOCUMENTATION_DATA = "/api/swagger/api-docs";

export const API_GET_PAYMENT_INSTRUMENT = "/api/external-id-management";
export const API_UPDATE_PAYMENT_INSTRUMENT_V1 = "/api/external-id-management/update/:id/:status";
export const API_UPDATE_PAYMENT_INSTRUMENT = "/api/external-id-management/:instrumentType/:id";
export const API_DELETE_PAYMENT_INSTRUMENT = "/api/external-id-management/:instrumentType/:id";

export const API_GET_STATUS_REASONS = "/api/settings/statusReasons";
export const API_GET_TEMPLATE_STATUS_REASONS = "/api/settings/statusReasons/template";
export const API_CREATE_STATUS_REASON = "/api/settings/statusReasons";
export const API_UPDATE_STATUS_REASON = "/api/settings/statusReasons";
export const API_GET_STATUS_REASON_ITEM = "/api/settings/statusReasons/:id";
export const API_DELETE_STATUS_REASON_ITEM = "/api/settings/statusReasons/:id";

export const API_GET_STATUS_REASONS_CONNECTION_DEPOSIT = "/api/settings/statusReasons/type/DEPOSIT";
export const API_GET_STATUS_REASONS_CONNECTION_WITHDRAWAL = "/api/settings/statusReasons/type/WITHDRAWAL";
export const API_GET_STATUS_REASONS_CONNECTION_ITEM = "/api/settings/statusReasons/type/:type/:gatewayCode";
export const API_UPDATE_STATUS_REASONS_CONNECTION = "/api/settings/statusReasons/mapping";
export const API_DELETE_STATUS_REASONS_CONNECTION_ITEM = "/api/settings/statusReasons/type/:type/:gatewayCode";

export const API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING = "/api/monitoring/not-completed-transactions-alert-per-merchant";
export const API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_ITEM = "/api/monitoring/not-completed-transactions-alert-per-merchant/:id";
export const API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_TEMPLATE = "/api/monitoring/not-completed-transactions-alert-per-merchant/template";

export const API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_PAYMENT_GATEWAY_CODES =
  "/api/monitoring/not-completed-transactions-alert-per-merchant/gateways";
export const API_GET_MERCHANT_PENDING_TRANSACTIONS_MONITORING_PAYMENT_GATEWAY_CODES_BY_MERCHANT =
  "api/monitoring/not-completed-transactions-alert-per-merchant/:merchantId/gateways";

export const API_CREATE_MERCHANT_PENDING_TRANSACTIONS_MONITORING = "/api/monitoring/not-completed-transactions-alert-per-merchant";
export const API_UPDATE_MERCHANT_PENDING_TRANSACTIONS_MONITORING = "/api/monitoring/not-completed-transactions-alert-per-merchant";
export const API_DELETE_MERCHANT_PENDING_TRANSACTIONS_MONITORING = "/api/monitoring/not-completed-transactions-alert-per-merchant/:id";

export const API_CREATE_MERCHANT_SLACK_CHANNEL_SETUP = "/api/merchants-slack-channels";
export const API_GET_MERCHANT_SLACK_CHANNELS = "/api/merchants-slack-channels/merchant/:merchantId";
export const API_DELETE_MERCHANT_SLACK_CHANNEL_SETUP = "/api/merchants-slack-channels/:id";

export const API_GET_MONITORING_PER_MERCHANT_DEPOSIT = "/api/monitoring-settings-per-merchant/deposit";
export const API_GET_MONITORING_PER_MERCHANT_WITHDRAWAL = "/api/monitoring-settings-per-merchant/withdrawal";
export const API_GET_MONITORING_PER_MERCHANT_ITEM = "/api/monitoring-settings-per-merchant/:id";
export const API_GET_MONITORING_PER_MERCHANT_TEMPLATE = "/api/monitoring-settings-per-merchant/template";
export const API_CREATE_MONITORING_PER_MERCHANT = "/api/monitoring-settings-per-merchant";
export const API_UPDATE_MONITORING_PER_MERCHANT = "/api/monitoring-settings-per-merchant";
export const API_DELETE_MONITORING_PER_MERCHANT = "/api/monitoring-settings-per-merchant/:id";
export const API_GET_MONITORING_PER_MERCHANT_GATEWAY_CODES = "api/monitoring-settings-per-merchant/:merchantId/gateways";
