import React, { useMemo } from "react";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useFEListSort, useFormFilters } from "system/helpers/hooks";
import Card from "components/atoms/Card";
import { Filter16, Search16 } from "components/atoms/IconsCreateFactory"
import { useModal } from "modules/modal/hooks";
import { Button } from "carbon-components-react";
import { useGetPaymentInstruments } from "./apiHooks";
import { useSearchFormConfig, useTableColumns } from "./constantHooks";
import { useGetOnActionStatusUpdate } from "./hooks";
import { ArrayOptionsMapRes } from "system/helpers/types";
import { filterOptions } from "./constants";

const PaymentInstrumentManagement = () => {
  const { _t } = useTrans();
  const columns = useTableColumns();
  const searchFormConfig = useSearchFormConfig();
  const { hideModal } = useModal();
  const filterByOptions: ArrayOptionsMapRes = useMemo(
    () => filterOptions(_t),
    [_t]
  );
  const filterInitialValues = { filter: filterByOptions[0].value };

  const normalizeReqData = (data: any, filtersInitial: any = {}) => {
    const normalizeDate = { ...data };
    if (data?.date) {
      normalizeDate.fromDate = data?.date?.fromDate;
      normalizeDate.toDate = data?.date?.toDate;
    } else if (isMobile && !data?.date) {
      normalizeDate.fromDate = filtersInitial.fromDate;
      normalizeDate.toDate = filtersInitial.toDate;
    }
    
    if (normalizeDate.filter === 'billingName') {
      const words = normalizeDate.value.trim().split(/\s+/)
      normalizeDate.value = `${words[0] || ""} ${words[1] || ""}`.trim()
    }
    
    !isMobile && delete normalizeDate.date;
    delete normalizeDate.searchFrom;

    return normalizeDate;
  };
  
  const { 
    isMobile,
    showFilters,
    mobileSearchFormConfig,
    filters,
    setFilters,
    filtersMobile,
  } = useFormFilters(
    filterInitialValues,
    searchFormConfig,
    FormBuilder,
    normalizeReqData
  )
  const { data = [], isLoading } = useGetPaymentInstruments(filters, {
    enabled: !!filters?.filter
  });
  const { getActionMenuItems } = useGetOnActionStatusUpdate(data, _t)

  const onFilterSubmit = async (data: any) => {
    const normalizeDate = normalizeReqData(data, filterInitialValues);
    isMobile 
      ? setFilters({...normalizeDate, ...filtersMobile})
      : setFilters(normalizeDate);
    hideModal('filters');
  };  

  return (
    <Card title={_t("payment_instrument_management")}>
      {isMobile
        ? <>
          <Button
            kind="ghost"
            style={{ color: "#252C32" }}
            onClick={() => showFilters()}
            renderIcon={Filter16}
          >
            {_t("filters")}
          </Button>
          <div className="mobile__search-form">
            <FormBuilder
              showSubmit={true}
              formItemsConfig={mobileSearchFormConfig}
              formProps={{
                submitBtnLabel: ' ',
                onSubmit: onFilterSubmit,
                initialValues: filterInitialValues,
                renderIcon: Search16,
                hasIconOnly: true,
                values: filters
              }}
            />
          </div>
        </>
        : <FormBuilder
          showSubmit={false}
          formItemsConfig={searchFormConfig}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: filterInitialValues,
            btnColumnParams: {
              lg: { span: 8 },
            },
          }}
        />
      }
      <Table
        totalRecords={+data?.length}
        columns={columns}
        data={useFEListSort(data, { sortField: 'createdDate', sortDir: "DESC" } )}
        isPagination
        isLoading={isLoading}
        cellProps={{ getActionMenuItems }}
      />
    </Card>
  );
};

export default PaymentInstrumentManagement;
