import { Close16, CloseOutline16, Reset16, SubtractAlt16, TrashCan16 } from "components/atoms/IconsCreateFactory"
import { Button } from "carbon-components-react";
import ModalBodyWithForm from "components/atoms/ModalBodyWithForm";
import { useModal } from "modules/modal/hooks";
import { TransType } from "system/translations/types";
import { useDeletePaymentInstrument, useUpdatePaymentInstrument } from "./apiHooks";
import { useUpdatePaymentInstrumentStatus } from "./constantHooks";
import { PaymentInstrumentDataType, PaymentInstrumentItemState, PaymentInstrumentItemType, UpdateStatusModalConfigsType } from "./types";
import { deleteWithConfirmValidation } from "./validation";
import { useConfirmModal } from "modules/modal/predefinedModals";

export const useGetOnActionStatusUpdate = (
  data: PaymentInstrumentDataType,
  _t: TransType
) => {
  const { mutate: onUpdatePaymentInstrument } = useUpdatePaymentInstrument();
  const { mutate: onDeletePaymentInstrument } = useDeletePaymentInstrument();
  const { showModal, hideModal } = useModal();
  const getUpdatePaymentInstumentStatusConfig = useUpdatePaymentInstrumentStatus();
  const { onConfirm } = useConfirmModal();

  const handleUpdatePaymentInstrument = (
    paymentInstrument: PaymentInstrumentItemType,
    status: PaymentInstrumentItemState
  ) => {
    const normalizedData = { ...paymentInstrument, state: status };
    status === 'DELETED'
      ? onDeletePaymentInstrument(normalizedData)
      : onUpdatePaymentInstrument(normalizedData)
    
    hideModal('deleteWithCommentModal')
  };

  const configureAndShowModal = ({
    status,
    modalConfigs,
    updatedPaymentInstrument,
  }: {
    status: PaymentInstrumentItemState;
    modalConfigs: UpdateStatusModalConfigsType;
    updatedPaymentInstrument: PaymentInstrumentItemType;
  }) => {
    showModal(
      {
        size: "sm",
        modalHeading: _t("do_you_want_to_{action}_PI_ID", {
          action: modalConfigs.title,
        }),
        component: ModalBodyWithForm,
        componentProps: {
          formBuilderProps: {
            formItemsConfig: getUpdatePaymentInstumentStatusConfig(
              status,
              modalConfigs
            ),
            showSubmit: false,
            formProps: {
              onSubmit: (formData: any) =>
                handleUpdatePaymentInstrument(formData, status),
              initialValues: {
                ...updatedPaymentInstrument,
              },
              validationSchema: deleteWithConfirmValidation(_t),
            },
          },
        },
        isShowScale: false,
        footer: Button,
        footerProps: {
          onClick: () => hideModal("deleteWithCommentModal"),
          children: _t("cancel"),
          renderIcon: Close16,
          kind: "tertiary",
          className: "cancel-modal-button",
        },
      },
      "deleteWithCommentModal"
    );
  };

  const onActionUpdate = ({
    id,
    status,
    modalConfigs,
  }: {
    id: any;
    status: PaymentInstrumentItemState;
    modalConfigs: UpdateStatusModalConfigsType;
  }) => {
    const updatedPaymentInstrument = data.find(
      (paymentInstrument: PaymentInstrumentItemType) =>
        paymentInstrument.id === id
    );

    if (updatedPaymentInstrument) {
      if (status === 'DELETED') {
        onConfirm({
          onOk: () => onDeletePaymentInstrument(updatedPaymentInstrument),
          modalHeading: _t("do_you_want_to_{action}_PI_ID", {
            action: _t("remove"),
          }),
          bodyText: _t('action_cannot_be_updone'),
          onOkText: _t("remove"),
          onOkKind: "danger",
          renderIcon: TrashCan16,
          isShowScale: false,
        });
      } else {
        configureAndShowModal({
          status,
          modalConfigs,
          updatedPaymentInstrument,
        });
      }
    }
  };

  const getActionMenuItems = ({
      isActive,
      canDeactivateOnPSPSide,
    }: {
      isActive: boolean,
      canDeactivateOnPSPSide: boolean,
    }) => {
    const createMenuItem = ({ title, status, icon, type }: any) => ({
      title,
      onClick: ({ allData }: any) =>
        onActionUpdate({
          id: allData?.id,
          status,
          modalConfigs: {
            title,
            icon,
            type,
          },
        }),
      IconComponent: icon,
      type,
    });
  
    const deactivateButton = createMenuItem(
      {
        title: _t("deactivate_on_PSP_side"),
        status: "SUSPENDED",
        icon: SubtractAlt16,
        type: "danger",
      },
    );
  
    const actionMenuItems = [
      createMenuItem(
        {
          title: isActive ? _t("block") : _t("unblock"),
          status: isActive ? "INACTIVE" : "ACTIVE",
          icon: isActive ? CloseOutline16 : Reset16,
          type: isActive ? "danger" : undefined,
        }
      ),
      createMenuItem(
        {
          title: _t("remove"),
          status: "DELETED",
          icon: TrashCan16,
          type: "danger",
        }
      ),
    ];
  
    if (canDeactivateOnPSPSide) {
      actionMenuItems.splice(1, 0, deactivateButton);
      actionMenuItems.pop()
    }
  
    return actionMenuItems;
  };
  
  return { getActionMenuItems };
};
