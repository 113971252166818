import React from "react";
import Card from "components/atoms/Card";
import { useTrans } from "system/translations/hooks";
import { generatePath, useHistory } from "react-router-dom";
import Loader from "components/atoms/Loader";
import {
  useDeleteStatusReasonConnection,
  useGetStatusReasonConnectionDeposit,
  useGetStatusReasonConnectionWithdrawal
} from "./apiHooks";
import { useTableColumns, useTableColumnsExpand } from "./constantHooks";
import { Add16, TrashCan16, Edit16 } from "components/atoms/IconsCreateFactory"
import Table from "modules/table";
import { isMobile } from "system/config/constant";
import ButtonLink from "components/atoms/ButtonLink";
import { APP_ROUTES } from "system/router/constants";
import { TabsType } from "components/atoms/Tabs/types";
import Tabs from "components/atoms/Tabs";
import { useGetHelperFunctions } from "./hooks";
import { StatusConnectionItemType } from "./types";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { useConfirmModal } from "modules/modal/predefinedModals";

const StatusReasonsConnection = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const { data: dataDeposit, isLoading: isLoadingDeposit } = useGetStatusReasonConnectionDeposit();
  const { data: dataWithdrawal, isLoading: isLoadingWithdrawal } = useGetStatusReasonConnectionWithdrawal();
  const { mutate: onDelete } = useDeleteStatusReasonConnection();
  const { onConfirm } = useConfirmModal();
  const { getExpandData } = useGetHelperFunctions()
  const columns = useTableColumns();
  const columnsExpand = useTableColumnsExpand()

  const onUpdate = ({ allData }: { allData: StatusConnectionItemType, id: number }) => {
    const { gatewayCode, type } = allData
    const link = generatePath(APP_ROUTES.statusReasonsConnection.update, { gatewayCode, type });
    history.push(link, history.location);
  };

  const onDeleteWithConfirm = ({allData}: any) => {
    const { gatewayCode, type } = allData
    onConfirm({ onOk: () => onDelete({ gatewayCode, type }) });
  };

  const actionMenuItems = [
    {
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({ allData }: { allData: StatusConnectionItemType, id: number }) => {
        const { gatewayCode, type } = allData

       return  generatePath(APP_ROUTES.statusReasonsConnection.update, { gatewayCode, type })
      },
      icon: <Edit16 />
    },
    {
      title: _t("delete"),
      onClick: onDeleteWithConfirm,
      icon: <TrashCan16 />,
      type: "danger",
    },
  ];
  const tabs: TabsType = {
    deposit: {
      label: _t("deposit_status_reasons"),
      content: (
        <Table
          columns={columns}
          isLoading={isLoadingDeposit}
          isPagination={true}
          data={getExpandData(dataDeposit?.deposit)}
          totalRecords={getExpandData(dataDeposit?.deposit).length}
          cellProps={{
            actionMenuItems,
            paymentType: "deposit",
          }}
          tableName={'TableWithSubComponentTable'}
          expandColumns={columnsExpand}
          sudComponentDataKeySelector={"statuses"}
          rowSubComponent={
            <Table
              data={getExpandData(dataDeposit?.deposit)}
              columns={columnsExpand}
            />
          } 
          />
      ),
    },
    withdrawal: {
      label: _t("withdrawal_status_reasons"),
      content: (
        <Table
          columns={columns}
          isLoading={isLoadingWithdrawal}
          isPagination={true}
          data={getExpandData(dataWithdrawal?.withdrawal)}
          totalRecords={getExpandData(dataWithdrawal?.withdrawal).length}
          cellProps={{
            actionMenuItems,
            paymentType: "withdrawal",
          }}
          tableName={'TableWithSubComponentTable'}
          expandColumns={columnsExpand}
          sudComponentDataKeySelector={"statuses"}
          rowSubComponent={
            <Table
              data={getExpandData(dataWithdrawal?.withdrawal)}
              columns={columnsExpand}
            />
          }
        />
      ),
    },
  };

  if (isLoadingDeposit || isLoadingWithdrawal ) {
    return <Loader formOverlay />;
  }

  return (
    <Card
      title={_t("status_connection")}
      extra={
        <RoleWrapper accessTo={"ROLE_MODIFY_STATUS_REASONS"}>
          <>
            {isMobile
              ? <ButtonLink
                className="button-create__link"
                isExpressive
                renderIcon={Add16}
                linkTo={APP_ROUTES.statusReasonsConnection.create}
              />
              : <ButtonLink
                isExpressive
                renderIcon={Add16}
                linkTo={APP_ROUTES.statusReasonsConnection.create}
                title={_t("create")}
              />
            }
          </>
        </RoleWrapper>
      }
    >
      <Tabs tabs={tabs} name={"status_reasons"} />
    </Card>
  );
};

export default StatusReasonsConnection;
