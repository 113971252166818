import { useMemo } from "react";
import {
  useDeleteMonitoringPerMerchant,
  useGetMonitoringPerMerchantDeposit,
  useGetMonitoringPerMerchantWithdrawal
} from "./apiHooks";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useFormFilters, useListPagination } from "system/helpers/hooks";
import Card from "components/atoms/Card";
import { useHistory, generatePath } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { Edit16, CloseOutline16, Add16 } from "components/atoms/IconsCreateFactory";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { useSearchFormConfig } from "./constantHooks";
import { useTableColumns } from "./constantHooks";
import config from "system/config";
import { getUrl } from "system/helpers/helperFunctions";
import { TabsType } from "components/atoms/Tabs/types";
import Tabs from "components/atoms/Tabs";
import AdaptiveFilterForm from "components/molecules/AdaptiveFilterForm";

const MerchantSuccessRateMonitoringPerMerchant = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const { pagination: depositPagination, setPagination: setDepositPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const { pagination: withdrawalPagination, setPagination: setWithdrawalPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const searchFormConfig = useSearchFormConfig();
  const { 
    isMobile,
    showFilters: showFiltersDeposit,
    filters: filtersDeposit,
    setFilters: setFiltersDeposit,
  } = useFormFilters({}, searchFormConfig, FormBuilder)
  const { 
    showFilters: showFiltersWithdrawal,
    filters: filtersWithdrawal,
    setFilters: setFiltersWithdrawal,
  } = useFormFilters({}, searchFormConfig, FormBuilder)

  const requestParamsDeposit = useMemo(
    () => ({ ...filtersDeposit, ...depositPagination }),
    [filtersDeposit, depositPagination]
  );
  const requestParamsWithdrawal = useMemo(
    () => ({ ...filtersWithdrawal, ...withdrawalPagination }),
    [filtersWithdrawal, withdrawalPagination]
  );
  const { data: dataDeposit, isLoading } = useGetMonitoringPerMerchantDeposit(requestParamsDeposit);
  const { data: dataWithdrawal} = useGetMonitoringPerMerchantWithdrawal(requestParamsWithdrawal);
  const { onConfirm } = useConfirmModal();
  const { mutate: onDelete } = useDeleteMonitoringPerMerchant();
  const columns = useTableColumns();
  const onUpdate = ({ id }: any) => {
    const link = generatePath(APP_ROUTES.merchantSuccessRateMonitoring.update, { id });
    history.push(link, history.location);
  };
  const onDeleteWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: () => onDelete({ id }) });
  };

  const onFilterSubmitDeposit = async (data: any) => {
    setFiltersDeposit(data);
    setDepositPagination(config.defaultInitialPagination);
  };

  const onFilterSubmitWithdrawal = async (data: any) => {
    setFiltersWithdrawal(data);
    setWithdrawalPagination(config.defaultInitialPagination);
  };

  const actionMenuItems = [
    { 
      title: _t("edit"),
      onClick: onUpdate,
      getLink: ({id}: any) => getUrl(APP_ROUTES.merchantSuccessRateMonitoring.update, id),
      icon: <Edit16 /> 
    },
    {
      title: _t("deactivate"),
      onClick: onDeleteWithConfirm,
      icon: <CloseOutline16 />,
      type: "danger",
    },
  ];

  const tabs: TabsType = {
    deposit: {
      label: _t("deposit_methods"),
      content: (
        <>
          <AdaptiveFilterForm 
            isMobile={isMobile}
            searchFormConfig={searchFormConfig}
            showFilters={showFiltersDeposit}
            onFilterSubmit={onFilterSubmitDeposit}
          />
          <Table
            columns={columns}
            isLoading={isLoading}
            onPagination={setDepositPagination}
            data={dataDeposit?.content || []}
            isPagination
            totalRecords={dataDeposit?.totalElements}
            pageIndex={depositPagination.page}
            pageSize={depositPagination.perPage}
            cellProps={{
              actionMenuItems,
              paymentType: "deposit",
            }}
          />
        </>
      ),
    },
    withdrawal: {
      label: _t("withdrawal_methods"),
      content: (
        <>
          <AdaptiveFilterForm 
            isMobile={isMobile}
            searchFormConfig={searchFormConfig}
            showFilters={showFiltersWithdrawal}
            onFilterSubmit={onFilterSubmitWithdrawal}
          />
          <Table
            columns={columns}
            onPagination={setWithdrawalPagination}
            isLoading={isLoading}
            data={dataWithdrawal?.content || []}
            isPagination
            totalRecords={dataWithdrawal?.totalElements}
            pageIndex={withdrawalPagination.page}
            pageSize={withdrawalPagination.perPage}
            cellProps={{
              actionMenuItems,
              paymentType: "withdrawal",
            }}
          />
        </>
      ),
    },
  };

  return (
    <Card
      title={_t("merchant_success_rate_monitoring")}
      extra={
        <>
         {isMobile
          ? <ButtonLink
              className="button-create__link"
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.merchantSuccessRateMonitoring.create}
              addHref = {true}
            />
          : <ButtonLink
              isExpressive
              renderIcon={Add16}
              linkTo={APP_ROUTES.merchantSuccessRateMonitoring.create}
              addHref = {true}
              title={_t("create")}
            />
        }
        </>
      }
    >
      <Tabs tabs={tabs} name={"merchant_success_rate_monitoring"} />
    </Card>
  );
};

export default MerchantSuccessRateMonitoringPerMerchant;
