import React, { useRef, useEffect } from "react";
import { TextArea as CarbonTextArea } from "carbon-components-react";
import { TextAreaProps } from "components/atoms/TextArea/types";

const TextArea: React.FC<TextAreaProps> = ({
  id = "textArea",
  labelText,
  value,
  onChange,
  hideLabel = false,
  autoExpand = false,
  ...rest
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (autoExpand && textAreaRef.current) {
      const adjustHeight = (e?: any) => {
        const textarea = textAreaRef.current!;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      };
      adjustHeight();

      const textarea = textAreaRef.current;
      textarea.addEventListener("input", adjustHeight);

      return () => textarea.removeEventListener("input", adjustHeight);
    }
  }, [autoExpand, value]);

  return (
    <CarbonTextArea
      {...rest}
      ref={textAreaRef}
      id={id}
      onChange={(e) => onChange(e?.target?.value)}
      value={value}
      labelText={labelText}
      hideLabel={hideLabel}
    />
  );
};

export default TextArea;
